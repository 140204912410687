<template>
  <!--<div class="container" v-resize:throttle="onResize">-->
  <div class="container">
    <!-- 헤더 -->
    <div class="header-container flex">
      <div class="header">
        <div id="logo">
          <a href="#service"><img src="../assets/img/logo/logo_origin.png" alt=""></a>
        </div>
        <div class="flex menu">
          <div class="header-menu table">
            <div class="table-cell" :style="cell(16)"><a href="#service">서비스</a></div>
            <div class="table-cell" :style="cell(15)"><a href="#theme">테마</a></div>
            <div class="table-cell" :style="cell(16)"><a href="#fee">요금제</a></div>
            <div class="table-cell" :style="cell(25)"><a href="#customerCase">고객 사례</a></div>
            <div class="table-cell" v-if="!isLogin" :style="cell(25)" @click="$refs.signModal.open();"><a>로그인</a></div>
            <div class="table-cell" v-else :style="cell(25)" @click="logout()"><a>로그아웃</a></div>
            <div class="table-cell mobile" :style="cell(28)" style="text-align: right;"><span class="header-start-btn" style="display: inline-block; line-height: 31px; position: relative; right: 5px;" @click="onModal">시작하기</span></div>
          </div>
          <div class="pc header-start-btn" @click="onModal" style="line-height: 35px;">시작하기</div>
        </div>
      </div>
    </div>

    <!-- 메인 소개 상단 -->
    <div class="content-main-top-container flex relative" id="service">
      <div class="content-main-top flex">
        <div class="text">
          <div class="title">개발자가 없어서 <br />창업을 못하고 계시나요?</div>
          <div class="content">런치팩을 이용하면 아이디어만 있어도 <br>  웹/앱 서비스를 창업할 수 있습니다. <br />
            <span style="font-weight:bold">무엇을 기다리시나요? <br> 지금 바로 시장검증/시장선점 하세요!</span></div>
          <div class="btn" @click="onModal">솔루션 이용 신청</div>
        </div>
        <div class="image">
          <img src="../assets/img/img_main@2x.png">
        </div>
      </div>
    </div>

    <div class="image-wave">
      <img src="../assets/img/img_main_wave@2x.png">
    </div>

    <!-- 메인 소개 하단 -->
    <div class="page-container-1">
      <div class="content-main-bottom-container">
        <div class="content-main-bottom">
          <div class="card-items flex" style="flex-wrap: wrap;">
            <div class="card-item" style="margin: 0px 20px 20px 0px;">
              <div class="numbering">1</div>
              <div class="img"><img src="../assets/img/img_card_01.png"></div>
              <div class="text">솔루션 이용 신청</div>
            </div>
            <div class="card-item">
              <div class="numbering">2</div>
              <div class="img"><img src="../assets/img/img_card_02.png"></div>
              <div class="text">결제완료</div>
            </div>
            <div class="card-item" style="margin: 0px 20px 20px 0px;">
              <div class="numbering">3</div>
              <div class="img"><img src="../assets/img/img_card_03.png"></div>
              <div class="text">관리자 페이지 개설</div>
            </div>
            <div class="card-item">
              <div class="numbering">4</div>
              <div class="img"><img src="../assets/img/img_card_04.png"></div>
              <div class="text">화면/DB 구성</div>
            </div>
            <div class="card-item">
              <div class="numbering">5</div>
              <div class="img"><img src="../assets/img/img_card_05.png"></div>
              <div class="text">내 서비스 시작!</div>
            </div>
          </div>
          <div class="content-main-bottom-content">
            <div class="title">런치팩과 함께라면 <br>
              누구나 만들 수 있는 웹 앱 서비스</div>
            <div class="content">런치팩은 창업자들을 위해 개발자 없이도<br> 직접 웹/앱 서비스를 제작할 수 있는 솔루션입니다. <br>
              제작하고자 하는 서비스에 맞춰 솔루션을 세팅하고 <br> 서비스화면과 관리정보를 구성하여 서비스를 시작하세요</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 소개내용 -->
    <div class="page-container-2 flex">
      <div class="content-1-container">
        <div class="content-1">
          <div class="title">창업자가 원하는 서비스에<br class="mobile"> 딱 맞는 테마와 <br class="pc"> 마음대로 화면을<br class="mobile"> 수정할 수 있는 빌더 서비스</div>
          <div class="content">디자인 변경할 때 마다
            디자이너에게 요청하고<br class="mobile"> 개발업체에게 요청하고.. <br> 지치진 않으셨나요?
            런치팩 솔루션은 제공되는 UI 빌더를 통해 <br>
            모든 서비스 화면 페이지를 원하는대로 디자인할 수 있습니다. <br>
            드래그 앤 드롭과 단축키를 이용하여 손쉽게 수정하세요</div>
          <div class="img"><img src="../assets/img/img_laptop.png">
            <div class="video-container">
              <video src="/static/video/demovideo_builider.mp4"
                     onclick="this.play();"
                     autoplay="autoplay" loop="loop"></video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-container-3">
      <div class="content-2-container">
        <div class="content-2">
          <div class="title">서비스를 처음 운영 하더라도 <br>
            손쉽게 관리할 수 있게 해주는 <br class="mobile"> 맞춤형 관리자 페이지</div>
          <div class="content">런치팩 솔루션은 서비스 제작 뿐만 아니라 <br>
            비즈니스 운영이 가능하도록 맞춤형 관리자 페이지를 제공합니다. <br>
            서비스 운영을 위해 별도의 개발과정은 필요 없습니다. <br>
            솔루션 이용 즉시 운영에 필요한<br class="mobile"> 정산, 회원, 상품 등을 바로 관리할 수 있습니다. <br>
          </div>
          <div class="img"><img src="../assets/img/img_laptop.png">
            <div class="content-slide-customdb" id="content-slide-customdb">
              <slick ref="slick3"
                     @afterChange="onChange"
                     :options="options2">
                <div v-for="(item, idx) in customDBSlideItems" :key="'slide-'+idx" class="item slick3-item">
                  <div style="overflow: hidden;">
                    <img :src="item.img" class="slick3-img">
                  </div>
                </div>
              </slick>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-container-4">
      <div class="content-3-container">
        <div class="content-3">
          <div>
            <div class="title">바로 초기 시장을 선점 하실 수 있게 <br>
              전체 클라이언트 단을 충분히 제공
            </div>
            <div class="content">
              <div v-for="(chk,idx) in checks" :key="'chk'+idx"><div><i class="material-icons">check</i></div><div class="text">{{chk}}</div></div>
            </div>
          </div>
          <div class="img"><img src="../assets/img/img_info.png"></div>
        </div>
      </div>
    </div>

    <!-- 테마 소개 -->
    <div class="gradinet-background">
      <div class="page-container-5 flex">
        <div class="content-4-container flex">
          <div class="content-4">
            <div>
              <div class="relative">
                <div id="theme" style="position:absolute;"></div>
                <img src="../assets/img/img_theme_2.png">
              </div>
            </div>
            <div class="bottom">
              <div class="title">인테리어 견적서비스 테마</div>
              <div class="content">인테리어 업체들의 포트폴리오를 모아서 보고<br class="mobile"/> 견적 요청을 할 수 있는 플랫폼팩 테마상품입니다.</div>
              <div class="content-resv">*다양한 테마 상품이 앞으로도 지속적으로 출시됩니다</div>
              <!--<div class="flex" style="justify-content: center;"><a target="_blank" href="http://demo.launchpack.co.kr"><div class="btn">데모보기</div></a></div>-->
              <div class="flex" style="justify-content: center;"><div class="btn" @click="vDemo=true">데모보기</div></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 소개내용 슬라이드 -->
    <div class="page-container-6 flex">

        <div class="card-container">
          <!--<div class="btn-arrow" @click="$refs.slick.prev()"><i class="material-icons">keyboard_arrow_left</i></div>-->
          <!--<div class="content-slide flex">-->
          <!--<slick ref="slick" :options="options">-->
          <div class="slide-item" v-for="(item, idx) in slideItems" :key="'slide-'+idx">
            <img :src="item.img">
            <div class="text">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
          <!--</slick>-->
          <!--</div>-->
          <!--<div class="btn-arrow" @click="$refs.slick.next()"><i class="material-icons">keyboard_arrow_right</i></div>-->
        </div>

    </div>
    <!-- 소개내용 요금제 테이블 -->
    <div style="position: relative; background-color: #FAFAFA;" id="fee">
      <div class="price-table-container flex">
        <div class="price-table">
          <div class="price-table-title">테마 이용 플랜</div>
          <div class="price-table-in">
            <div class="title">
              <div class="first-cell"></div>
              <div>테마 개설비</div>
              <div>테마 이용료</div>
              <div class="pink">2018년 프로모션 할인</div>
              <div>총 개설비</div>
              <div>총 월 이용료</div>
              <div>견적팩</div>
              <div>UI 빌더</div>
              <div>관리자페이지 (PC전용)</div>
              <div>파트너페이지 (PC전용)</div>
              <div>기본 호스팅</div>
              <div class="small-text">
                <div>호스팅 사양(초기 스타트업 기준) <br>
                  RAM 2GB, SSD 40GB, 월 전송량 최대 3TB <br>
                  트래픽 초과 시 비용은 별도</div>
              </div>
            </div>
            <div class="content">
              <div class="first-cell"><div class="cell-title">월 요금제</div><div class="cell-content">MVP 시장검증을 빠르게 하고자 하는 스타트업 <br> 개설비가 부담스러운 예비창업자, 예비스타트업</div></div>
              <div><span class="mobile price-title">개설비</span><span class="accent-gray">0</span> 원</div>
              <div><span class="mobile price-title">월 이용료</span><span class="accent-gray">687,500</span> 원</div>
              <div><span class="mobile pink">2018년 프로모션 할인<br></span><span class="pink">-107,550 원 할인</span></div>
              <div><span class="accent"><del>&nbsp;0&nbsp;</del></span> 원<span class="gray">(개설비)</span></div>
              <div><span class="accent">580,000</span> 원</div>
              <div><div class="mobile">견적팩</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">UI 빌더</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">관리자페이지 (PC전용)</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">파트너페이지 (PC전용)</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">기본 호스팅</div><i class="pc material-icons">check</i></div>
              <div class="mobile">
                <div class="small-text"><div>호스팅 사양(초기 스타트업 기준) <br>RAM 2GB, SSD 40GB, 월 전송량 최대 3TB <br>트래픽 초과 시 비용은 별도</div></div>
              </div>
              <div class="flex" style="justify-content: center;">
                <div><div class="btn" @click="onModal('type_month')">이용신청</div><span class="accent-samll">* 기본계약기간 3개월</span></div>
              </div>
            </div>
            <div class="content">
              <div class="first-cell"><div class="cell-title">개설비 + 월 요금제</div><div class="cell-content">안정적으로 서비스를 하고자 하는 스타트업 <br> 정부지원금을 통해 제작하려고 하는 스타트업</div></div>
              <div><span class="mobile price-title">개설비</span><span class="accent-gray">11,000,000</span> 원</div>
              <div><span class="mobile price-title">월 이용료</span><span class="accent-gray">160,000</span> 원</div>
              <div><span class="mobile pink">2018년 프로모션 할인<br></span><span class="pink">-3,010,000 원 할인<span class="gray">(개설비)</span></span></div>
              <div><div><span class="accent">7,990,000</span> 원<span class="gray">(개설비)</span></div></div>
              <div><span class="accent">160,000</span> 원</div>
              <div><div class="mobile">견적팩</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">UI 빌더</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">관리자페이지 (PC전용)</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">파트너페이지 (PC전용)</div><i class="pc material-icons">check</i></div>
              <div><div class="mobile">기본 호스팅</div><i class="pc material-icons">check</i></div>
              <div class="mobile">
                <div class="small-text"><div>호스팅 사양(초기 스타트업 기준) <br>RAM 2GB, SSD 40GB, 월 전송량 최대 3TB <br>트래픽 초과 시 비용은 별도</div></div>
              </div>
              <div class="flex" style="justify-content: center;">
                <div><div class="btn" @click="onModal('type_opend')">이용신청</div></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="img-colorful-wave">
        <img src="../assets/img/img_price_table.png">
      </div>
    </div>

    <div class="page-container-3 flex" id="customerCase">
      <div class="flex">
        <div class="content-5">
          <div class="title">고객사례</div>
          <div class="content">이미 런치팩으로 초기시장진입을<br class="mobile"/> 빠르게 하고 있는 스타트업들 입니다</div>
          <div class="content-slide-customer">
            <slick ref="slick2"
                   @afterChange="onChange"
                   :options="options2">
              <div v-for="(item, idx) in customerSlideItems" :key="'slide-'+idx" class="item slick2-item">
                <div style="overflow:hidden;">
                  <img :src="item.img">
                  <div class="item-title">{{ item.title }}</div>
                  <div class="item-content">{{ item.content }}</div>
                </div>
                <div class="flex" style="justify-content: center; padding-bottom: 8px;"><a target="_blank" :href=item.link><div class="btn">둘러보기</div></a></div>
              </div>
            </slick>
          </div>
          <!-- dot -->
          <div class="dot-area">
            <div class="dot" :class="{'dot-selected':currentSlide === 0}" @click="$refs.slick2.next()"></div>
            <div class="dot" :class="{'dot-selected':currentSlide === 1}" @click="$refs.slick2.prev()"></div>
            <div class="dot" :class="{'dot-selected':currentSlide === 2}" @click="$refs.slick2.prev()"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="page-container-7 flex">
      <div class="content-6-container flex">
        <div class="content-6">
          <div class="catchphrase">기술이 없어도, 큰 자본이 없어도 <br>
            누구나 창업해볼 수 있는 세상을 꿈꿉니다</div>
          <div class="logo">
            <img src="../assets/img/logo/logo_white.png" alt="">
          </div>
          <div class="privacy">대표 염민지  |  사업자등록번호 694-87-01163 <br class="mobile"/> | 통신판매업신고 2018-광주동구-0192 | 개인정보책임관리자 이남호 <br>
            광주광역시 동구 동계천로 150 아이플렉스 206호  |  고객문의 support@launchpack.co.kr  |  대표전화 1544-9247 (11:00~19:00)</div>
        </div>
      </div>
    </div>

    <div class="bg-demo" v-if="vDemo" @click="vDemo=false">
      <i class="material-icons btn-close">close</i>
      <div class="frame-demo" @click.stop.prevent="">
        <iframe src="http://demo.launchpack.co.kr"></iframe>
      </div>
    </div>

    <sweet-modal ref="modal">
      <div class="modal-container">
        <div class="modal-title">
          <div class="title">서비스 이용 신청</div>
          <div class="content">입력하신 신청 정보를 토대로 담당자가 상담 후 솔루션 이용을 도와드립니다.</div>
        </div>
        <div class="modal-content flex">
          <div class="modal-content-title">
            <div>
              <div>서비스</div>
            </div>
            <div>
              <div>서비스 한줄 소개</div>
            </div>
            <div id="modal-content-title-second-cell">
              <div>남기실 말</div>
            </div>
            <div>
              <div>요금제 선택</div>
            </div>
            <div>
              <div>성함</div>
            </div>
            <div>
              <div>이메일</div>
            </div>
            <div>
              <div>연락처</div>
            </div>
            <div>
              <div></div>
            </div>
            <div>
              <div></div>
            </div>
          </div>
          <div class="modal-content-items-container">
            <div class="modal-content-items">
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="text"
                  placeholder="서비스명"
                  maxlength="50"
                  :value="params.service_name"
                  @input="e => params.service_name = e.target.value"
                ></div>
                <div class="pc"><input
                  type="text"
                  maxlength="50"
                  :value="params.service_name"
                  @input="e => params.service_name = e.target.value"
                ></div>
              </div>
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="text"
                  placeholder="서비스 한 줄 설명"
                  maxlength="50"
                  :value="params.service_desc"
                  @input="e => params.service_desc = e.target.value"
                ></div>
                <div class="pc"><input
                  type="text"
                  maxlength="50"
                  :value="params.service_desc"
                  @input="e => params.service_desc = e.target.value"
                ></div>
              </div>
              <div id="modal-content-item-second-cell">
                <div class="mobile"><textarea
                  type="text"
                  placeholder="문의내용"
                  :value="params.content"
                  @input="e => params.content = e.target.value"
                ></textarea></div>
                <div class="pc"><textarea
                  type="text"
                  :value="params.content"
                  @input="e => params.content = e.target.value"
                ></textarea></div>
              </div>
              <div class="modal-content-item">
                <lp-drop-down placeholder="요금제"
                              :val.sync="params.product"
                              :options="[
                  { val: '580000', label: '월 요금제' },
                  { val: '10000000', label: '개설비 + 월 요금제' }
                ]"
                ></lp-drop-down>
              </div>
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="text"
                  maxlength="20"
                  placeholder="대표자명"
                  :value="params.name"
                  @input="e => params.name = e.target.value"
                ></div>
                <div class="pc"><input
                  type="text"
                  maxlength="20"
                  :value="params.name"
                  @input="e => params.name = e.target.value"
                ></div>
              </div>
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="email"
                  placeholder="이메일"
                  maxlength="50"
                  :value="params.email"
                  @input="e => params.email = e.target.value"
                ></div>
                <div class="pc"><input
                  type="email"
                  maxlength="50"
                  :value="params.email"
                  @input="e => params.email = e.target.value"
                ></div>
              </div>
              <div class="modal-content-item">
                <div class="mobile"><input
                  type="phone"
                  :value="params.phone"
                  @input="e => params.phone = e.target.value"
                  placeholder="연락처"
                  maxlength="13"
                  @keyup="onKeyup"
                ></div>
                <div class="pc"><input
                  type="phone"
                  :value="params.phone"
                  @input="e => params.phone = e.target.value"
                  maxlength="13"
                  @keyup="onKeyup"
                ></div>
              </div>
              <div class="lp-privacy-wrap" v-show="isShow">
                <strong>개인정보 수집/이용목적</strong>
                <p>1) 고객지원: 각종 문의에 대한 지원<br>
                  2) 마케팅 및 광고에 활용: 신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</p>
                <br>
                <strong>수집하는 개인정보의 항목</strong>
                <p>성명, 연락처, 이메일<br>
                  또한, 서비스 이용과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br>
                  − IP주소, OS/브라우저 종류, 방문 일시, 서비스 이용기록, 접속로그, 쿠키</p>
                <br>
                <strong>개인정보의 보유 및 이용기간</strong>
                <p>보존 기간은 3년이며, 정보 제공자가 삭제를 요청할 경우 즉시 파기합니다.<br>
                  고객님의 정보는 개인정보 보호법에 따라 보호되며 위의 사항 외에 별도로 사용하지 않을 것을 약속드립니다.</p>
              </div>
              <div class="modal-content-item">
                <div class="privacy"><input type="checkbox" v-model="privacy"><a href="#" @click="onClick">개인정보취급방침</a>을 읽었으며 동의합니다.</div>
              </div>
            </div>
            <div class="btn" @click="contactUs">신청하기</div>
          </div>
        </div>
      </div>
    </sweet-modal>
    <lp-sign-modal ref="signModal"></lp-sign-modal>
    <lp-toast></lp-toast>
  </div>
</template>

<script>
  import Slick from 'vue-slick'
  import { SweetModal } from 'sweet-modal-vue';
  import LpDropDown from './LpDropDown'
  import LpToast from './component/LpToast'
  import resize from 'vue-resize-directive'
  import LpSignModal from "./modal/LpSignModal";

  export default {
    name: 'lp-web-intro',

    directives: {
      resize,
    },

    components: {
      LpSignModal,
      Slick,
      SweetModal,
      LpDropDown,
      LpToast
    },

    mounted() {
      if(window.innerWidth < 700) {
        this.slideAmount = 1;
      }

      else {
        this.slideAmount = 3;
      }
    },

    methods: {
      cell(val) {
        return { width: `${val}%`};
      },
      onResize() {
        let old = this.slideAmount;
        if(window.innerWidth < 700) {
          this.slideAmount = 3;
        }

        else {
          this.slideAmount = 1;
        }

        if(old !== this.slideAmount)
          this.$refs.slick.reSlick();
      },
      onKeyup() {
        this.params.phone = this.autoHyphenPhone(this.params.phone);
      },
      change() {
        //console.log(e);
      },
      onChange(event, slick, currentSlide) {
        this.currentSlide = currentSlide;
      },
      onModal (type) {
        if (type === 'type_month') this.params.product = '580000';
        if (type === 'type_opend') this.params.product = '10000000';
        this.$refs.modal.open();
      },
      onClick () {
        this.isShow?this.isShow=false:this.isShow=true;
      },
      contactUs () {
        if(this.isValid() === false) return;
        this.$axios.post('public/launchpack/inquiry', this.params).then(res => {
          if (res.status === 200) {
            //console.log(res);
            this.$refs.modal.close();
            // modal text reset
            let keys = Object.keys(this.params);
            for(let i=0; i<keys.length; i++) {
              this.params[keys[i]] = '';
            }
            this.privacy = false;
          }
        }).catch(err => {
          if(!err.response.data.message)
            console.log(err.response.data.detail);
          else
            console.log(err.response.data.message);
        });
      },
      isValid() {
        let valid = true;
        let keys = Object.keys(this.params);
        for(let i=0; i<keys.length; i++) {
          let val = this.params[keys[i]];
          if(this.isError(keys[i], val)) {
            valid = false;
            break;
          }
          if(!this.privacy) {
            this.toast('개인정보취급방침 약관에 동의해주세요.');
            valid = false;
            break;
          }
        }
        return valid;
      },
      isError(key, val) {
        let error = false;
        if(val === '' || val === ' ')
          error = true;

        let msg = '';
        switch(key) {
          case 'service_name':
            msg = '서비스명을';
            break;
          case 'service_desc':
            msg = '서비스 한줄 소개를';
            break;
          case 'content':
            msg = '문의 내용을';
            break;
          case 'product':
            msg = '요금제를';
            break;
          case 'name':
            msg = '대표자명을';
            break;
          case 'email':
            msg = '이메일을';
            break;
          case 'phone':
            msg = '연락처를';
            break;
        }
        if(msg!=='' && error)
          this.toast(msg + ' 입력해주세요.');

        return error;
      },
      toast(msg) {
        this.$root.$emit('toast', msg);
      }
    },
    data() {
      return {
        vDemo: false,
        isShow: false,
        slick: undefined,
        currentSlide: 0,
        privacy: false,
        slideAmount: 3,
        screenWidth: screen.width,
        params: {
          service_name: '',
          service_desc: '',
          content: '',
          product: '',
          name: '',
          email: '',
          phone: ''
        },
        // options: {
        //   infinite: true,
        //   variableWidth: false,
        //   centerPadding: '0',
        //   centerMode: false,
        //   autoplay: true,
        //   autoplaySpeed: 2500,
        //   arrows: false,
        //   dots: false,
        //   slidesToShow: 3,
        //   slidesToScroll: 1
        // },
        options2: {
          infinite: true,
          variableWidth: false,
          centerPadding: '0',
          centerMode: false,
          autoplay: false,
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1
        },
        checks: [
          'PC웹 (모바일 웹 뷰로 제공)',
          '모바일 웹',
          '안드로이드 앱',
          'iOS 앱',
          '관리자 페이지',
          '파트너(업체) 관리 페이지'
        ],
        slideItems: [
          {
            img: '/static/img/img_slide_05.png',
            title: '견적서 발송을 위해\n파트너 페이지 제공',
            content: '입점 제휴 된 파트너 업체에서 바로\n 견적요청을 확인하고 고객에게 견적서를\n발송할 수 있게 파트너 관리자 페이지를 제공합니다'
          },
          {
            img: '/static/img/img_slide_06.png',
            title: '관리자 맞춤형\n견적요청 양식 폼',
            content: '고객이 견적 요청 시 작성하는 폼 양식을\n다양한 형태로 제공하여 원하는대로\n수정하고 조합하여 폼을 만들 수 있습니다'
          },
          {
            img: '/static/img/img_slide_03.png',
            title: '입점 파트너에게\n견적 발송',
            content: '사용자가 요청한 견적을 인테리어 \n파트너업체에게 직접 선정해서 보내거나\n자동으로 매칭해서 보낼 수 있습니다'
          },
          {
            img: '/static/img/img_slide_02.png',
            title: '테마 커스터마이징\nUI 빌더 지원',
            content: 'UI 빌더를 통해 화면디자인을\n창업자가 원하는대로 수정하고\n커스터마이징 할 수 있습니다'
          },
          {
            img: '/static/img/img_slide_04.png',
            title: '서비스 운영 관리\n솔루션 제공',
            content: '일반적으로 플랫폼 서비스에 필요한\n회원관리, 파트너관리, 상품관리, 공지사항관리\nFAQ 관리 등을 기본적으로 제공합니다'
          },
          {
            img: '/static/img/img_slide_01.png',
            title: '최신 UIUX를 반영한\n간결하고 직관적인 디자인',
            content: '사용자들이 어려워하지 않고\n쉽게 서비스를 이용할 수 있습니다'
          }
        ],
        customerSlideItems: [
          {
            img: '/static/img/img_customer_london.png',
            title: '런던티켓',
            content: '런던 뮤지컬 및 엔터테인먼트 티켓을\n모아서 보여주는 서비스',
            link: 'http://actuallyticket.com'
          },
          {
            img: '/static/img/img_customer_kidsday.png',
            title: '키즈데이',
            content: '영유아 체험학습 및 행사정보를\n모아서 보여주는 서비스',
            link: 'http://kidsday.kr'
          },
          {
            img: '/static/img/img_customer_farmerspace.png',
            title: '파머스페이스',
            content: '생산 원료가 필요한 가공업체와\n잉여 농산물을 보유한 농가를 이어주는 서비스',
            link: 'http://13.115.179.147/home'
          }
        ],
        customDBSlideItems: [
          {
            img: '/static/img/img_backoffice_01.png'
          },
          {
            img: '/static/img/img_backoffice_02.png'
          }
        ]
      }
    },

    computed: {
      options () {
        let options = {
          infinite: true,
          variableWidth: false,
          centerPadding: '0',
          centerMode: false,
          autoplay: true,
          autoplaySpeed: 2500,
          arrows: false,
          dots: false,
          slidesToShow: this.slideAmount,
          slidesToScroll: 1
        };
        return options
      }
    }
  }

</script>
